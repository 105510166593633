import {Fragment} from "react";
import { Typography } from "@material-tailwind/react";
import Text from "../components/global/Text";

const OpayPaymentStatus = () => {
  return (
    <Fragment>
      <div className={`w-full h-screen flex flex-col p-5 my-5 items-center justify-center`}>
        <div className={`flex flex-col items-center justify-center h-screen`}>
          <Typography
            variant={'h4'}
            color={'gray'}
            className={`font-bold text-center`}>
            Transaction Completed
          </Typography>

          <div className="mt-5">
            <Text
              text={'Tap on [X] to close'}
              className={`text-base text-[#282B59] font-bold normal-case`}
              weight={700}
            />
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default OpayPaymentStatus;
